<template>
   <el-col class="main">
      <el-row class="allocation">
      <!-- <div class="wechat-conf-box">
          <div class="config">
            <div class="title">企业微信配置</div>
            <el-row v-if="wechatEnterInfo.appId" class="deploy">
              <el-row class="deploy-box">
                <el-row class="title">
                  <el-row class="title-text" >{{ wechatEnterInfo.cfgName }}</el-row>
                </el-row>
                <el-row class="basic">
                  <el-row>
                    <img class="logo" referrerPolicy="no-referrer" :src="wechatEnterInfo.content.auth_corp_info.corp_square_logo_url" />
                    <span style="margin-left: 15px">{{ wechatEnterInfo.content.auth_corp_info.corp_name || '' }} </span>
                    <span class="tips">已认证服务号</span>
                    &lt;!&ndash; <span class="tips" style="background: #dedede">未认证服务号</span>&ndash;&gt;
                  </el-row>
                </el-row>
                <div class="content-text">
                  <div class="icon"></div>
                  <div class="content-titel">基本信息</div>
                </div>
                <div class="content-box">
                  <div class="form-text" style="width:930px;text-indent: 2em;">
                    <el-form>
                      <el-form-item label="appid：">
                        {{ wechatEnterInfo.appId }} <el-button type="text" class="text-btn" @click="copy(wechatEnterInfo.appId)"><i class="el-icon-copy-document"></i></el-button>
                      </el-form-item>
                      <el-form-item label="corpid：">
                        {{ wechatEnterInfo.content.auth_corp_info.corpid }} <el-button type="text" class="text-btn" @click="copy(wechatEnterInfo.content.auth_corp_info.alias)"><i class="el-icon-copy-document"></i></el-button>
                      </el-form-item>
                      <el-form-item label="公司名称：">
                        {{ wechatEnterInfo.content.auth_corp_info.corp_name }} <el-button type="text" class="text-btn" @click="copy(wechatconfig.content.auth_corp_info.corp_name)"><i class="el-icon-copy-document"></i></el-button>
                      </el-form-item>
                    </el-form>
                  </div>
                  <div  style="text-align: center; position: relative;min-width: 160px;">
                    <img style="width: 150px;" referrerPolicy="no-referrer" :src="wechatEnterInfo.content.auth_corp_info.corp_wxqrcode" />
                    &lt;!&ndash; <a :href="wechatconfig.content.authorizer_info.qrcode_url" style="position: relative;padding: 10px 15px;border-radius: 3px;text-decoration: none;margin-left: -154px;top: 30px;" class="uploadbtn bg-gradient"  target="_blank" referrerPolicy="no-referrer">下载公众号二维码</a>&ndash;&gt;
                  </div>
                </div>
              </el-row>
            </el-row>
            <div class="card" v-else>
              <el-image fit="fill" :src="require('../../../assets/icon/subscription.png')"></el-image>
              <el-button class="bg-gradient m-bottom-10" @click="handleConfig">配置企业微信</el-button>
              <el-button @click="empowerVisible = true">授权信息</el-button>
              <p class="p1">为保证所有的功能正常使用,</p>
              <p>授权时请把所有权限统一授权给达易住</p>
            </div>
          </div>
        </div>
        &lt;!&ndash; 企业微信授权信息 &ndash;&gt;
        <el-dialog class="empower-visible" title="授权信息填写" :visible.sync="empowerVisible" width="550px" :before-close="handleClose">
          <el-form ref="empowerForm" :model="empowerForm" required>
            <el-form-item label="企业ID" prop="corpId" required>
              <el-input class="width-280" v-model="empowerForm.corpId"></el-input>
            </el-form-item>
            <el-form-item label="服务商Secret" prop="corpSecret" required>
              <el-input class="width-280" v-model="empowerForm.corpSecret"></el-input>
            </el-form-item>
            <el-form-item label="应用回调token" prop="token" required>
              <el-input class="width-280" v-model="empowerForm.token"></el-input>
            </el-form-item>
            <el-form-item label="应用回调加密token" prop="encodingAESKey" required>
              <el-input class="width-280" v-model="empowerForm.encodingAESKey"></el-input>
            </el-form-item>
            <el-form-item label="通讯录回调Token" prop="bookToken" required>
              <el-input class="width-280" v-model="empowerForm.bookToken"></el-input>
            </el-form-item>
            <el-form-item label="通讯录回调EncodingAESKey" prop="bookEncodingAESKey" required>
              <el-input class="width-280" v-model="empowerForm.bookEncodingAESKey"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">取 消</el-button>
            <el-button type="primary" @click="saveConfig">确 定</el-button>
          </span>
        </el-dialog>-->

         <!--公众号-->
         <el-row class="deploy" >
            <el-row class="deploy-box" v-if="wechatconfig != '' ">
               <el-row class="title">
                  <el-row class="title-text" >{{wechatconfig.cfgName}}</el-row>
               </el-row>
               <el-row class="basic" v-if="wechatconfig.content.authorizer_info.nick_name != 'undefined' ">
                  <el-row>
                     <img class="logo" referrerPolicy="no-referrer" :src="wechatconfig.content.authorizer_info.head_img" /><span style="margin-left: 15px">{{ wechatconfig.content.authorizer_info.nick_name || '' }} </span>  <span v-if="wechatconfig.content.authorizer_info.service_type_info.id == 2 "  class="tips">已认证服务号</span> <span v-else class="tips" style="background: #dedede">未认证服务号</span>
                  </el-row>
               </el-row>
               <div class="content-text">
                  <div class="icon"></div>
                  <div class="content-titel">基本信息</div>
               </div>
               <div class="content-box">
                  <div class="form-text" style="width:930px;text-indent: 2em;">
                     <el-form>
                        <el-form-item label="appid：">
                           {{wechatconfig.appId}} <el-button type="text" class="text-btn" @click="copy(wechatconfig.content.id)"><i class="el-icon-copy-document"></i></el-button>
                        </el-form-item>
                        <el-form-item label="微信号：">
                           {{wechatconfig.content.authorizer_info.alias}} <el-button type="text" class="text-btn" @click="copy(wechatconfig.content.authorizer_info.alias)"><i class="el-icon-copy-document"></i></el-button>
                        </el-form-item>
                        <el-form-item label="主体名称：">
                           {{wechatconfig.content.authorizer_info.principal_name}} <el-button type="text" class="text-btn" @click="copy(wechatconfig.content.authorizer_info.principal_name)"><i class="el-icon-copy-document"></i></el-button>
                        </el-form-item>
                     </el-form>
                     <div class="bottom-btn">
                        <el-button type="text" @click="relieveright"><i class="el-icon-lock"></i> 解除绑定</el-button>
                        <el-button type="text" @click="afreshright"><i class="el-icon-s-fold"></i> 重新授权</el-button>
                        <el-button type="text" @click="dialogVisible = true"><i class="el-icon-s-operation"></i> 已授权列表</el-button>
                     </div>
                  </div>
                     <div  style="text-align: center; position: relative;min-width: 160px;">
                        <img style="width: 150px;" referrerPolicy="no-referrer" :src="wechatconfig.content.authorizer_info.qrcode_url + '?wx_fmt=jpeg'" />
                        <a :href="wechatconfig.content.authorizer_info.qrcode_url" style="position: relative;padding: 10px 15px;border-radius: 3px;text-decoration: none;margin-left: -154px;top: 30px;" class="uploadbtn bg-gradient"  target="_blank" referrerPolicy="no-referrer">下载公众号二维码</a>
                     </div>
               </div>

            </el-row>
            <el-row class="deploy-box" v-else>
               <el-row class="title">
                  <el-row class="title-text" >微信公众号配置</el-row>
               </el-row>
               <div class="img-box">
                  <img style="margin: 20px" src="../../../assets/icon/subscription.png">
                  <el-input type="text" v-model="officialappid" placeholder="请输入公众号appid" style="width: 80%" ></el-input>
                  <el-button style="margin-top: 20px"  class="bg-gradient" type="primary" @click="sweepCode('wechat-official')" >已有公众号，立即绑定</el-button>
                  <p style="margin-top: 10px;padding: 10px;">为保证所有的功能正常使用,</p>
                  <p>授权时请把所有权限统一授权给达易住</p>
               </div>
            </el-row>
         </el-row>
         <!--小程序-->
         <el-row class="deploy" >
            <el-row class="deploy-box">
               <el-row class="deploy-box" v-if="wechatappletconfigpath =='' ">
                  <el-row class="title">
                     <el-row class="title-text" >微信小程序配置</el-row>
                  </el-row>
                  <div class="img-box">
                     <img style="margin: 20px" src="../../../assets/icon/subscription.png">
                     <el-input type="text" v-model="officialappletid" placeholder="请输入小程序appid" style="width: 80%" ></el-input>
                     <el-button style="margin-top: 20px"  class="bg-gradient" type="primary" @click="sweepCode('wechat-applet')" >已有小程序，立即绑定</el-button>
                     <p style="margin-top: 10px;padding: 10px;">为保证所有的功能正常使用,</p>
                     <p>授权时请把所有权限统一授权给达易住</p>
                  </div>
               </el-row>
               <div class="form-text" v-else>
                  <el-row class="title">
                     <el-row class="title-text" >微信小程序配置</el-row>
                  </el-row>
                  <el-form style="margin-top: 40px">
                     <el-form-item label="小程序酒店列表链接">
                        {{wechatappletconfigpath.home_page.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.home_page.path)"><i class="el-icon-copy-document"></i></el-button>
                     </el-form-item>
                     <el-form-item label="小程序我的预售券链接">
                        {{wechatappletconfigpath.Presalecoupon.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.Presalecoupon.path)"><i class="el-icon-copy-document"></i></el-button>
                     </el-form-item>
                     <el-form-item label="小程序酒店房型列表链接">
                        {{wechatappletconfigpath.hotel_introduce.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.hotel_introduce.path)"><i class="el-icon-copy-document"></i></el-button>
                     </el-form-item>
                     <el-form-item label="小程序我的订单链接">
                        {{wechatappletconfigpath.my_order.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.my_order.path)"><i class="el-icon-copy-document"></i></el-button>
                     </el-form-item>
                     <el-form-item label="小程序入住人管理链接">
                        {{wechatappletconfigpath.reside_select.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.reside_select.path)"><i class="el-icon-copy-document"></i></el-button>
                     </el-form-item>
                     <el-form-item label="小程序商惠页面链接">
                        {{wechatappletconfigpath.shopping.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.shopping.path)"><i class="el-icon-copy-document"></i></el-button>
                     </el-form-item>
                     <el-form-item label="小程序限时秒杀链接">
                        {{wechatappletconfigpath.shopping_seckill.path}}<el-button type="text" class="text-btn" @click="copy(wechatappletconfigpath.Presalecoupon.path)"><i class="el-icon-copy-document"></i></el-button>
                     </el-form-item>

                  </el-form>
                  <div style="height: 40px" v-if="wechatappletconfig != '' ">
                     <img  class="qrcodeMap"  style="position: relative;left: 800px;top: -220px;" referrerPolicy="no-referrer" :src="wechatappletconfig.content.authorizer_info.qrcode_url + '?wx_fmt=jpeg' "  />
                     <a :href="wechatappletconfig.content.authorizer_info.qrcode_url" class="uploadbtn bg-gradient"  target="_blank" referrerPolicy="no-referrer">下载小程序二维码</a>
                  </div>


               </div>
            </el-row>

         </el-row>
         <!-- 抖音 -->
         <el-row class="deploy" style="margin-top: 3rem">
            <el-row class="deploy-box">
               <el-row class="title">
                  <el-row class="title-text">抖音开放平台</el-row>
                  <el-row style="float: right" v-show="tab == '1'">
                     <el-button v-show="thrill == false" @click="thrill = true">返回</el-button>
                     <el-button type="primary" v-show="thrill == false" @click="save('dy')">保存</el-button>
                     <el-button v-show="thrill == true" icon="el-icon-edit"  circle @click="modification('trill')"></el-button></el-row>
               </el-row>
               <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                  <el-menu-item index="1">基本信息</el-menu-item>
                  <el-menu-item index="2">权限配置</el-menu-item>
               </el-menu>
               <el-row v-show="tab === '1'">
                  <el-col class="message" v-show="thrill == false">
                     <el-col style="display: flex">
                        <el-row class="content">抖音应用openId</el-row>
                        <el-row>
                           <el-input
                           placeholder="抖音应用openId"
                           v-model="openId"
                           type="text"></el-input>
                        </el-row>
                     </el-col>
                     <el-col style="display: flex">
                        <el-row class="content">应用唯一标识Client Key</el-row>
                        <el-row>
                           <el-input
                           v-model="clientKey"
                           placeholder="应用唯一标识Client Key"
                           type="text"></el-input>
                        </el-row>
                     </el-col>
                     <el-col style="display: flex">
                        <el-row class="content">应用唯一标识对应的密钥Client Secret</el-row>
                        <el-row>
                           <el-input
                           v-model="clientSecret"
                           placeholder="应用唯一标识对应的密钥Client Secret"
                           type="text"></el-input>
                        </el-row>
                     </el-col>
                     <el-col style="display: flex">
                        <el-row class="content">授权回调地址</el-row>
                        <el-row>
                           <el-input
                           v-model="address"
                           placeholder="授权回调地址"
                           type="text">
                           </el-input>
                        </el-row>
                     </el-col>
                     <el-col style="display: flex">
                        <el-row class="content">授权状态标识</el-row>
                        <el-row>
                           <el-input
                           placeholder="授权状态标识"
                           v-model="identification"
                           type="text">
                           </el-input>
                        </el-row>
                     </el-col>
                  </el-col>
                  <el-col class="message" v-show="thrill == true">
                     <el-col style="display: flex">
                        <el-row class="content">抖音应用openId</el-row>
                        <el-row class="viewupdate">{{ openId }}</el-row>
                     </el-col>
                     <el-col style="display: flex">
                        <el-row class="content">应用唯一标识Client Key</el-row>
                        <el-row class="viewupdate">{{ clientKey }}</el-row>
                     </el-col>
                     <el-col style="display: flex">
                        <el-row class="content">应用唯一标识对应的密钥Client Secret</el-row>
                        <el-row class="viewupdate">{{ clientSecret }}</el-row>
                     </el-col>
                     <el-col style="display: flex">
                        <el-row class="content">授权回调地址</el-row>
                        <el-row class="viewupdate">{{ address }}</el-row>
                     </el-col>
                     <el-col style="display: flex">
                        <el-row class="content">授权状态标识</el-row>
                        <el-row class="viewupdate">
                           {{identification}}</el-row>
                     </el-col>
                  </el-col>
               </el-row>
               <el-row v-show="tab === '2'">
                  <el-menu
                        :default-active="activeIndex2"
                        class="el-menu-demo"
                        mode="horizontal"
                        @select="handleSelects">
                     <el-menu-item v-for="item in tableData" :key="item.id" :index="item.id"  >{{item.value}}</el-menu-item>
                  </el-menu>
                  <el-table :data="configuration" style="width: 100%">
                     <el-table-column prop="authName" label="权限名称"></el-table-column>
                     <el-table-column prop="scopes" label="Scope">
                        <template slot-scope="scope"><p v-for="(item,index) in scope.row.content" :key="index">{{item}}</p></template>
                     </el-table-column>
                     <el-table-column label="状态">
                        <template slot-scope="scope">
                           <el-switch @change="states(scope.row,scope.$index)" v-if="switchshow"  :active-value="!scope.row.switch"></el-switch>
                        </template>
                     </el-table-column>
                  </el-table>
               </el-row>
            </el-row>
         </el-row>
         <el-dialog
               title="已授权列表"
               :visible.sync="dialogVisible"
               width="50%">
            <el-checkbox-group v-model="checkedCities" @change="handleCheckedCitiesChange">
               <el-checkbox v-for="(item,index) in cities" :label="item.id" :key="index" :disabled="dialogVisible">
                  {{item.name}}
               </el-checkbox>
            </el-checkbox-group>
         </el-dialog>
         <!-- 是否授权成功遮罩  -->
         <el-drawer
               title="请您注意"
               :visible.sync="drawer"
               direction="ttb"
               :show-close="dialogVisible"
               :wrapperClosable="dialogVisible"
               style="height: 650px;">
            <span>请在新打开的页面完成授权!</span>
            <div class="bottom-btnbox" style="margin-top: 20px">
               <el-button type="primary" @click="seeabout">授权成功</el-button>
               <el-button type="primary" @click="drawer = false">授权失败</el-button>
            </div>
         </el-drawer>
      </el-row>
   </el-col>
</template>
<script>
import { urlObj } from "@/api/interface";
import { mapState } from 'vuex'
export default {
   watch: {
      hotelInfo: {
         handler(newVal, oldVal) {
            if (newVal.id !== oldVal.id  && oldVal) {
               this.hotelId = this.hotelInfo.id
               this.wechatappletconfigpath = ''
               this.wechatconfig = ''
               this.wechatappletconfig = ''
               this.seeabout()
            }
         },
         deep: true
      },
   },
  /*beforeRouteEnter(to, from, next) {
     next(vm => {
       vm.auth_code = vm.$route.query.auth_code
       vm.auth_code && vm.getPermanentCode()
     })
  },*/
   computed: {
      ...mapState(['hotelInfo'])
   },
   data() {
      return {
        wechatEnterInfo: '',
        auth_code: '',
        empowerVisible: false,
        empowerForm: {
          corpId: '',
          corpSecret: '',
          token: '',
          encodingAESKey: '',
          bookToken: '',
          bookEncodingAESKey: ''
        },

         drawer:false,
         dialogVisible:false,
         id: "",            //微信公众号ID
         AppId: "",              //开发者(AppID)
         AppSecret: "",          //开发者密码(appSecret)
         Token: "",              //令牌(token)
         encodingAESKey: "",     //消息加解密密钥(encodingAESKey)
         savechanges: true,      //保存  修改按钮切换
         limit: 10,
         page: 1,
         wechatconfig:'',//微信配置
         wechatappletconfig:'',//小程序配置
         officialappid:'',
         officialappletid:'',
         checkedCities:[],
         cities:[
            {name:'消息管理权限',id:1},
            {name:'用户管理权限',id:2},
            {name:'帐号服务权限',id:3},
            {name:'网页服务权限',id:4},
            {name:'微信小店权限',id:5},
            {name:'微信多客服权限',id:6},
            {name:'群发与通知权限',id:7},
            {name:'微信卡券权限',id:8},
            {name:'微信扫一扫权限',id:9},
            {name:'微信连WIFI权限',id:10},
            {name:'素材管理权限',id:11},
            {name:'微信摇周边权限',id:12},
            {name:'微信门店权限',id:13},
            {name:'自定义菜单权限',id:15},
            {name:'城市服务接口权限',id:22},
            {name:'广告管理权限',id:23},
            {name:'开放平台帐号管理权限',id:24},
            {name:'微信电子发票权限',id:26},
            {name:'快速注册小程序权限',id:27},
            {name:'小程序管理权限',id:33},
            {name:'微信商品库权限',id:34},
            {name:'微信卡路里权限',id:35},
            {name:'好物圈权限',id:44},
            {name:'微信一物一码权限',id:46},
            {name:'微信财政电子票据权限',id:47},
            {name:'服务号对话权限',id:54},
            {name:'服务平台管理权限',id:66},
            {name:'订阅通知权限',id:89},
         ],
         menulist:[
            {id:'VIDEO',value:'视频权限'},
            {id:'USER',value:'用户权限'},
            {id:'INTERACT',value:'互动权限'},
            {id:'DATA',value:'视频权限'},
            {id:'SPECIAL',value:'特殊权限'},
            {id:'JSBRIDGE',value:'JSBridge'}
         ],
         tableData: [],
         wechatappletconfigpath:[],
         activeIndex: "1",
         tab: "1",
         thrill:true,
         openId:'',              //抖音应用openId
         clientKey:'',           //应用唯一标识Client Key
         clientSecret:'',        //应用唯一标识对应的密钥clientSecret
         address:'',             //授权回调地址
         identification:'',      //授权状态标识
         activeIndex2:'VIDEO',
         tableDatas: [],
         state:'',
         allscope:'',
         configuration:[],
         scopes:[],
         openaccess:[],
         boosl:'',
         tabs:'VIDEO',
         switchshow:true,
         weiyi:[],
         douyincontent:'',
         hotelId:'',
      }
   },
   mounted() {
      this.hotelId =    this.hotelInfo.id
      this.seeabout()
   },
   methods: {
     /*handleConfig() {
       window.open(process.env.VUE_APP_BASE_API + urlObj.authURL)
     },
     getPermanentCode() {
       const url = urlObj.permanentCode,
             params = { code: this.auth_code, hotelId: this.hotelInfo.id }
       this.$axios.post(url, params).then(res => {
         if (!res.success) return
         this.$message({ message: '认证成功！', type: 'success' })
       })
     },
     // 保存企业微信配置
     saveConfig() {
       this.$refs.empowerForm.validate(valid => {
         if (!valid) return
         const url = urlObj.saveWechatEnterCfg,
             param = {
               hotelId: this.hotelInfo.id, content: JSON.stringify(this.empowerForm),
               hotelConfig: 'WECHAT_ENTERPRISE', companyCfg: 'true', appId: 'ww71a4d48033828078'
             };
         this.$axios.post(url, param, 'json').then(res => {
           if (!res.success) return
           this.empowerVisible = false
           this.$message({ message: '保存成功！', type: 'success' })
         })
       })
     },
     // 关闭配置弹窗
     handleClose() {
       this.empowerVisible = false
     },*/

      //点击复选框
      changecheck(){
         this.$message.info('暂不支持修改')
      },
      //复选框组
      handleCheckedCitiesChange(value) {
         let checkedCount = value.length;
         this.checkAll = checkedCount === this.cities.length;
         this.isIndeterminate = checkedCount > 0 && checkedCount < this.cities.length;
      },
      //授权微信小程序
      getwechatconfig(url){
         this.drawer = true
         window.open(url)
      },
      //跳转扫码授权
      sweepCode(cfgCode){
         let content ={
            authorization_info:{
               authorizer_appid:''
            }
         }
         if (cfgCode == 'wechat-official'){
            content.authorization_info.authorizer_appid = this.officialappid
         }else{
            content.authorization_info.authorizer_appid = this.officialappletid
         }

         const url = urlObj.saveWechatHotelCfg;
         const param = {
            hotelId: this.hotelId,
            cfgCode: cfgCode,
            contents:content
         };
         this.$axios.post(url, param, 'json').then((res) => {
            if (res.status == 200){
               this.getwechatconfig('/oauth2/wechat/thirdparty/getAuthURL/web')

            }
         })
      },
      //下载小程序二维码
      uploadqrcodeMap(url){
         window.location(url+'?wx_fmt=jpeg')
      },

      //重新授权
      afreshright(){
         this.$message.info('功能尚未开发')
      },
      //解除绑定
      relieveright(){
         this.$message.info('功能尚未开发')
      },
      //刷新页面
      reload(){
         location.reload()
      },
      //授权失败

      //复制
      copy(data) {
         const copyContent = data;
         // 创建input标签存放需要复制的文字
         const inputTag = document.createElement("input");
         // 把文字放进input中，供复制
         inputTag.value = copyContent;
         document.body.appendChild(inputTag);
         // 选中创建的input
         inputTag.select();
         // 执行复制方法， 该方法返回bool类型的结果，告诉我们是否复制成功
         const copyResult = document.execCommand("copy");
         // 操作中完成后 从Dom中删除创建的input
         document.body.removeChild(inputTag);
         // 根据返回的复制结果 给用户不同的提示
         if (copyResult) {
            this.$message.success("复制成功！"); // 自己定义的提示语
         } else {
            console.log("复制失败！"); // 自己定义的提示语
         }
      },

      //查询配置公众号
      seeabout() {
         const url = urlObj.EnquiryPublicAccount;
         var ads = ["wechat-official","douyin-platform","douyin-scope","wechat-applet", 'wechat-enterprise']
         const param = {
            hotelId: this.hotelId,
            limit: this.limit,
            page: this.page,
            cfgCodes: ads,
         };
         this.$axios.post(url, param, 'json').then((res) => {
            this.tableData = res.records;
            this.tableData.forEach((item) => {
               if (item.hasOwnProperty('content'))item.content = JSON.parse(item.content);

               if (item.cfgCode === "wechat-official" && item.hasOwnProperty('content')) {
                  let content = item.content;
                  this.id = content.id;
                  this.AppId = content.appId;
                  this.AppSecret = content.appSecret;
                  this.Token = content.token;
                  this.encodingAESKey = content.encodingAesKey;
                  this.wechatconfig  = item

               }
               if (item.cfgCode === "wechat-applet") {
                  let content = item.content;
                  this.id = content.id;
                  this.AppId = content.appId;
                  this.AppSecret = content.appSecret;
                  this.Token = content.token;
                  this.encodingAESKey = content.encodingAesKey;
                  this.wechatappletconfig = item
               }
               if (item.cfgCode === "douyin-platform") {
                  let content = item.content;
                  this.openId = content.id;
                  this.clientKey = content.clientKey;
                  this.clientSecret = content.clientSecret;
                  this.address = content.redirectUri;
                  this.identification = content.state;
               }
               if (item.cfgCode === "douyin-scope") {
                  this.douyincontent = item.content;
               }
              if (item.cfgCode === 'wechat-enterprise') {
                this.wechatEnterInfo = item
              }
            });
            if (this.wechatappletconfig != ''){
               this.getAppletPages()
            }

            //微信授权列表
            if (this.wechatconfig != ''){
               this.wechatconfig.content.authorization_info.func_info.forEach(
                     (item) => {
                        this.checkedCities.push(item.funcscope_category.id);
                     }
               );
               this.cities = this.cities.filter((item, index) => {
                  return (item.id = this.checkedCities[index]);
               });
            }
            this.drawer = false
         });

      },

      //获取小程序配置
      getAppletsconfing(){
         const url = urlObj.getAppletsconfing
         const param = {
            hotelId: this.hotelId,
            hotelCode:'dayizhu'
         };
         this.$axios.post(url, param).then((res) => {
            this.getAppletPages()
         })
      },
      //获取小程序配置文件中指定的页面
      getAppletPages(){
         const url = urlObj.getAppletsconfing
         const param = {
            hotelId: this.hotelId,
            hotelCode:'dayizhu'
         };
         this.$axios.post(url, param).then((res) => {
            if (res.status == 200) {
               this.wechatappletconfigpath = res.records
               if (!this.wechatappletconfigpath.home_page.url){
                  this.getAppletsconfing()
               }
            }
         })
      },

      //权限配置
      states(val,i){
         let contentscope = this.douyincontent.scope.split(",")
         let scope = val.scopes.split(",")
         this.switchshow = false
         if (val.switch == true){
            contentscope.map((item,index)=>{
               scope.map((items,i)=>{
                  if (contentscope[index] == items){
                     contentscope.splice(index,1)
                  }
               })
            })
            this.configuration[i].switch = false
            this.switchshow = true
         }else{
            contentscope.push(val.scopes)
            this.configuration[i].switch = true
            this.switchshow = true
         }
         var reg = new RegExp('"',"g");
         this.douyincontent.scope = contentscope.toString().replace(reg, "")
         let contentdata = {
            scope:contentscope.join(","),
            id: this.openId,
         }
         const url = urlObj.saveWechatHotelCfg;
         const param = {
            cfgCode: "douyin-scope",
            hotelId: this.hotelId,
            cfgName: '抖音权限配置',
            content:JSON.stringify(contentdata)
         }
         this.$axios.post(url, param).then((res) => {
            if (res.status == 200) {
               this.$message({
                  message: "修改成功!",
                  type: "success",
               });
               this.trillQuerys()
            }
         })
      },
      //查询抖音权限配置
      trillQuerys(){
         // let scope = ['toutiao.video.create', 'toutiao.video.data', 'xigua.video.data', 'xigua.video.create']
         const url = urlObj.trillQuery;
         const param = {
            type:this.tabs
         };
         this.$axios.post(url, param).then((res) => {
            this.configuration = res.records
            this.configuration.forEach((item,i)=>{
               item.switch = false
               this.switchshow = false
               item.content = item.scopes.split(",")
               if (this.douyincontent.scope.search(item.scopes) != -1){
                  item.switch = true
               }else{
                  item.switch = false
               }
            })
            this.switchshow = true
         })
      },
      //抖音开发平台 tab
      //
      handleSelect(key, keyPath) {
         if(key == 2){
            this.trillQuerys()
         }
         this.tab = key;
      },
      handleSelects(key, keyPath){
         this.tabs = key;
         this.trillQuerys()
      },
      modification(data) {
         if (data === 'OfficialAccounts') {
            this.savechanges = false;
         }
         if (data === 'trill') {
            this.thrill = false
         }
      },
      save(identification) {
         if(identification == 'gzh'){
            if (this.cfgName == "") {
               this.$message.error("微信公众号ID不能为空!");
               return;
            }
            if (this.AppId == "") {
               this.$message.error("APPID不能为空!");
               return;
            }
            if (this.AppSecret == "") {
               this.$message.error("开发者密码不能为空!");
               return;
            }
            if (this.Token == "") {
               this.$message.error("token命令不能为空!");
               return;
            }
            if (this.encodingAESKey == "") {
               this.$message.error("消息密钥不能为空!");
               return;
            }
            const url = urlObj.saveWechatHotelCfg;
            const contents = {
               id: this.id,
               appId: this.AppId,
               appSecret: this.AppSecret,
               token: this.Token,
               encodingAesKey: this.encodingAESKey,
            };
            const param = {
               cfgCode: "wechat-official",
               hotelId: this.hotelId,
               cfgName: '微信公众号',
               content: JSON.stringify(contents),
            };
            this.$axios.post(url, param).then((res) => {
               if (res.status === 200) {
                  this.$message({
                     message: "修改成功!",
                     type: "success",
                  });
                  this.savechanges = true;
                  this.seeabout();
               }
            });
         }

         if(identification == 'dy'){
            if (this.openId == "") {
               this.$message.error("抖音应用openId不能为空!");
               return;
            }
            if (this.clientKey == "") {
               this.$message.error("应用唯一标识Client Key不能为空!");
               return;
            }
            if (this.clientSecret == "") {
               this.$message.error("应用唯一标识对应的密钥Client Secret不能为空!");
               return;
            }
            if (this.address == "") {
               this.$message.error("授权回调地址不能为空!");
               return;
            }
            if (this.identification == "") {
               this.$message.error("授权状态标识不能为空!");
               return;
            }
            const url = urlObj.saveWechatHotelCfg;
            const contents = {
               id: this.openId,
               clientKey: this.clientKey,
               clientSecret: this.clientSecret,
               redirectUri: this.address,
               state: this.identification
            };
            const param = {
               cfgCode: "douyin-platform",
               hotelId: this.hotelId,
               cfgName: '抖音开放平台',
               content: JSON.stringify(contents),
            };
            this.$axios.post(url, param).then((res) => {
               if (res.status == 200){
                  this.$message({
                     message: "修改成功!",
                     type: "success",
                  });
                  this.seeabout();
                  this.thrill = true
               }
            });
         }

      },
   },
};
</script>

<style scoped lang="scss">
.empower-visible{
  .el-form-item{ margin-bottom: 20px }
  ::v-deep .el-form-item__error{ margin-left: 150px }
  ::v-deep .el-form-item__label{ width: 150px }
}


.wechat-conf-box{
  margin: 2rem auto; width: 97%; box-shadow: 0 0 4px #c2d7fb inset;
  .config{
    width: 97%; margin: 0 auto; padding: 5px;
    .title{
      font-size: 18px; font-weight: 900; line-height: 60px;
      border-bottom: 1px solid #dde0e7; text-indent: 1rem;
    }
    .card{
      width: 220px; height: 300px; border: 1px #dedede solid; margin: 20px;
      padding: 15px; text-align: center; font-size: 12px;
      p{ opacity: 0.5 }
      .p1 { padding: 10px 0; margin-top: 10px }
      .el-image { margin: 20px }
    }
  }
}

.tips{
   background-color: #5cd551;
   color: #fff;
   font-size: 12px;
   margin-left: 15px;
   padding: 0 5px;
   border-radius: 2px;
}
.main {
   width: 100%;
   background: white;
}
.allocation {
   width: 100%;
}
.deploy {
   width: 97%;
   box-shadow: 0px 0px 4px #c2d7fb inset;
   margin: auto;
   margin-top: 2rem;
   margin-bottom: 2rem;
}
.deploy-box{
   width: 97%;
   margin: auto;
}
.describe{font-size: 12px; color: #bac2ce;margin-top: 1rem;}
.viewupdate{font-size: 15px;color: black;}
.title {
   width: 100%;
   height: 60px;
   line-height: 60px;
   border-bottom: 1px solid #dde0e7;
   font-size: 1.1rem;
   padding-left: 1rem;
}
.title-text{
   float: left;
   font-size: 18px;
   font-weight: 900;
}
.content-box{
   display: flex;
}
.basic {
   width: 100%;
   line-height: 60px;
   font-size: 1.1rem;
   padding-left: 1rem;
}
.logo{
   width: 50px;
   height: 50px;
   position: relative;
   top: 18px;
   display: initial;
}
.basic .el-row {
   font-size: 17px;
   width: 350px;
   border-bottom: 1px solid #dedede;
}
/deep/ .el-table__header{margin-top:1rem;}
.message {
   width: 100%;
   padding-bottom: 2rem;
   padding-left: 1rem;
}
.message .el-col {
   margin-top: 1.5rem;
}
.message .el-col .el-input {
   min-width: 300px;
}
/deep/ .has-gutter tr th{text-align: center; background: #f2f2f2; color: #667688;font-weight: bold;}
/deep/ tbody .el-table__row td{text-align: center;}
.content {
   width: 180px;
   text-align: left;
   font-size: 14px;
}
@media screen and (max-width: 1366px) {
   .main {
      width: 1200px;
   }
}
.content-text{
   display: flex;
   padding: 1.5rem 0;
}
.content-titel{
   text-indent: 1em;
}
.icon{
   padding: 1px;
   border: 1px solid #409eff;
   background: #409eff;
   margin-left: 1rem;
}
.form-text{
   margin:0 1rem 1rem;
}
.form-text a{
   position: relative;
   left: 365px;
   top: -200px;
   padding: 10px 15px;
   border-radius: 3px;
   text-decoration: none;
}
.text-btn{
   margin: 0 10px;
}
.bottom-btn{
   border-top: 1px solid #dedede;
   margin-top: 1rem;
   width: 350px;
   text-indent: 0;
}
.bottom-btn button{
   padding: 20px 10px;
}
.qrcodeMap{
   margin: 30px 140px;
   width: 150px;
}
.uploadbtn{
   margin-left: 140px;
}
/deep/.el-form-item{
   margin-bottom: 0;
}
/deep/.el-checkbox{
   width: 33%;
   padding: 5px 0;
}
/deep/.el-checkbox__input.is-disabled.is-checked .el-checkbox__inner{
   background-color: #409eff;
   border-color: #DCDFE6;
}
/deep/.el-checkbox__input.is-disabled + span.el-checkbox__label{
   color: #000;
   cursor: not-allowed;
}
.img-box{
   width: 220px;
   height: 300px;
   border: 1px solid #dedede;
   margin: 20px;
   padding: 15px;
   text-align: center;
   font-size: 12px;
}
.img-box p{
   opacity: 0.5;
}
/deep/.el-drawer__open .el-drawer.ttb{
   height: 150px;
   width: 350px;
   margin: 15% auto;
   text-align: center;
}
/deep/.el-drawer__header{
   -webkit-box-align: center;
   -ms-flex-align: center;
   align-items: center;
   color: #2d2d2d;
   display: -webkit-box;
   display: -ms-flexbox;
   display: flex;
   margin-bottom: 32px;
   border: none;
   padding: 20px;
   padding-bottom: 0;
   font-size: 18px;
}
/deep/ .el-drawer:focus {
   outline: none;
}
.el-drawer /deep/ :focus {
   outline: none;
}
/deep/.el-drawer__header > :first-child{
   outline: none;
}
/deep/.el-drawer__wrapper :focus{
   outline: none;
   border: none;
}
</style>
